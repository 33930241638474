<template>
  <div class="container">
    <div class="banner">
      <h1>多幸学院</h1>
      <p>
        DuoXing College
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "DuoXingCollege",
  data() {
    return {};
  },
  computed: {},
  methods: {}
};
</script>
<style scoped lang="scss">
  .banner {
    height: 450px;
    background-image: url("../assets/image/duoXingCollege/banner.png");
    background-size: 100% 450px;
    background-repeat: no-repeat;
    display: flex;
    display: -webkit-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & h1 {
      height: 90px;
      font-size: 64px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #FFFFFF;
      line-height: 90px;
      margin-bottom: 0;
    }

    & p {
      height: 30px;
      font-size: 22px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #FFFFFF;
      line-height: 30px;
      margin-top: 10px;
    }
  }
</style>
